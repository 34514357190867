.preview-image{
    display: flex;
    width: 170px;
    height: 170px;
    overflow-clip-margin: content-box;
    border-radius: 10px;
}

.products-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 20px;



}

.products-container div {
  max-width: fit-content;
}

.productDetails {
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    /* border: 1px solid black; */
}

.preview-image:hover {
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px #b6b6b6;
}

.preview-image:hover {
    visibility: visible;
}

.welcomebanner {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    margin-bottom: 50px;
    /* font-weight: 1px; */

}
.username {
    margin-left: 5px;
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
}

[data-theme='dark'] {
    --background: #212121;
    --primary: #fff;
  }


  body {
    background-color: var(--background);
    color: var(--primary);
  }

  [data-theme='dark'] .nameprice {
    background-color: #282c34;
    color: #fff;
  }


@media (max-width: 1275px) {
    .products-container {
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      max-width: 80vw;
    }


    /* .welcomebanner {
      display: flex;
    justify-content: center;
    align-items: center;
      width: 100vw;
    } */
  }
/* @media (max-width: 767px) {
    .products-container {
      grid-template-columns: repeat(2, 1fr);
    }
  } */
