.sign-up {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.signuperrors {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-label {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.submit-label {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
}

.submit-label:hover {
    background-color: #3e8e41;
}

.input{
    margin-top: 0.5em;
}
