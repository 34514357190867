/* TODO Add site wide styles */
html {

    margin: 0 14.5%;
    font-family: 'Oxygen', sans-serif;
    overflow-x: hidden;
    /* height: 100%; */

}

#root {
  min-width: 1080px;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
padding-bottom: 3.5rem;    /* Footer height */
}

#footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 7.5rem;            /* Footer height */
  background-color: #f7f7f7;
    color: black;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    font-size: 14px;
    width: 100%;
    /* margin-top: 6rem; */
    font-size: 18px;
}

#Footer-div {
    display: flex;
    /* min-height: 100vh; */
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
  }

  #dev {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }

  #dev div {
    font-weight: bold;
    margin-bottom: 5px;
  }

  #dev a {
    color: rgb(231, 92, 194);
    text-decoration: none;
    margin: 2px;
  }

  #dev a:hover {
    text-decoration: underline;
  }

li {
    list-style-type: none;
}

ul {
    padding-inline-start: 0;
}

a {
    text-decoration: none;
    color: black;
}

button {
    cursor: pointer;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
  }

main {
    flex: 1;
  }

footer {
    background-color: rgb(242, 240, 132);
    color: black;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    /* margin-top: auto; */
    width: 140%;
    margin-left: -22%;



  }





  .Footer-div {
    display: flex;
    /* min-height: 100vh; */
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
  }

  .dev {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }

  .dev div {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .dev a {
    color: rgb(231, 92, 194);
    text-decoration: none;
    margin: 2px;
  }

  .dev a:hover {
    text-decoration: underline;
  }

  :root {
    --background: #fff;
    --primary: #000;
  }

  [data-theme='light'] {
    --background: #fff;
    --primary: #000;
  }

  [data-theme='dark'] {
    --background: #282c34;
    /* --primary: #fff; */
  }

  body {
    background-color: var(--background);
    color: var(--primary);
  }
