#modal {
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color:white;
  width: 576px;
  padding: 2em ;
  max-height: 80%;
  overflow-x: hidden;
  border-radius: 10px;
}

@media screen and (max-width:750px){
  #modal-content{
    width:90%;
  }
}
body {
  background-color: var(--background);
  color: var(--primary);
}

[data-theme='dark'] #modal-content{
  background-color: #282c34;
  color: white;
}
