.cart-image {
    height: 150px;
    width: 150px;
    object-fit: cover;
    margin-bottom: 20px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-bottom: 1px solid rgb(182, 182, 182);

}
.checkoutbutton {
    background-color: #35cc07;
    border-radius: 4px;
    border: none;
    color: #FFFFFF;
    font-size: 18px;
    padding: 8px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}
.cartdeletebutton {
    background-color: #fc3239;
    border-radius: 4px;
    border: none;
    color: #FFFFFF;
    font-size: 12px;
    padding: 6px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}

.checkoutbutton:hover {
    background-color: #099b09;
    cursor: pointer;
}

.cartdeletebutton:hover {
    background-color: #9b2609;
    cursor: pointer;
}

.empty-cart {
    display: flex;
    align-items: center;
    /* padding-top: 10px; */
    flex-direction: column;
    /* padding-top: 120px; */
  }

  .image-gif {
    display: flex;
    align-items: center;
  }



  [data-theme='dark'] {
    --background: #282c34;
    --primary: white;
  }

  body {
    background-color: var(--background);
    color: var(--primary);
  }

  .empty-cart-message {
    color: red;
  }
