.mainProductDetail {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .imageandreviews .img{
    width: 50%;
    float: left;

  }
  .product-detail {
    width: 60%;
    float: right;
  }

  .productdetailprice {
    display: flex;
    /* border: 1px solid red; */
    justify-content: flex-end;
  }
  .productdetailsize {
    display: flex;
    /* border: 1px solid red; */
    /* justify-content: flex-end; */
  }
  .productdetailcolor {
    display: flex;
    /* border: 1px solid red; */
    /* justify-content: flex-end; */
  }
  .description-detail {
    overflow-wrap: normal;
    height: 200px;
  }

  .product-images img{
    border-radius: 20px;
    width: 450px;
    height: 450px;
  }
  .text {
    font-weight: bolder;
  }
  .addtocart button{

    background-color: #35cc07;
    border-radius: 4px;
    border: none;
    color: #FFFFFF;
    font-size: 18px;
    padding: 8px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }
  .addtocart:hover button:hover{
    background-color: #287a0e;
  }
  .product-button button {
    background-color: #f7f7f7;
    border: 1px solid #d3d3d3;
    color: #666;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 15px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    outline: none;
}
.product-button:hover button:hover{
    background-color: #c2bebe;
}
@media (max-width: 767px) {
  .mainProductDetail {
    flex-direction: column;
    align-items: center;
  }
  .imageandreviews {
    order: 2;
    margin-top: 20px;
  }
  .imageandreviews .img{
    width: 80%;
    float: none;
  }
  .product-detail {
    width: 80%;
    float: none;
  }
  .description-detail {
    height: auto;
  }
  .product-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .product-price {
    font-size: 24px;
    margin-top: 20px;
  }
  .product-size {
    margin-top: 10px;
  }
  .product-color {
    margin-top: 10px;
  }
  .add-review {
    order: 1;
    margin-bottom: 20px;
  }
}
