

.navStart {
  /* background-color: red; */
  /* align-content: center; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
  max-width: 120rem;
  /* max-width: 80vw; */
  }


.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  margin-top: -0.05%;
  /* position: relative; Add this line */
  z-index: 1;
}

.profile-dropdown {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px #ccc;
  z-index: 2;
  margin-top: 15px;
  width: 150px;
  height: auto;
  font-weight: lighter;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border-radius: 5%;
  position: absolute;
}

.hidden {
  display: none;
}


.add-button {
  display: flex;
  color: var(--primary);
  margin-left: auto;
}

.add-button:hover {
  background-color: #b6b6b6;
}

.order-button:hover {
  background-color: #b6b6b6;
}


.home-button {
  display: flex;
  color: transparent;
  background-image: url(./pantsylogoHome.png);
  height: 100px;
  width: 100px;

}

.cart-button {

  display: flex;
  justify-content: center;
  /* margin-left: auto;  */
  width: 500%;
  font-size: 22px;
  color: var(--primary);
}

.order-button {
  display: flex;
  color: var(--primary);
  margin-left: auto;
}




.profilebutton {
 border: hidden;
 color: var(--primary);
 background: none;


}


.profilebutton:hover .profilebuttonimage {
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px #b6b6b6;
}


.profile-dropdown li {
  margin-bottom: 10px;
  word-break: break-word;
}

.profile-dropdown button {
padding: 10px;
margin: 5px;
background-color: #ccc;
color: #fff;
border: none;
cursor: pointer;
}

.profile-dropdown button:hover {
  background-color: rgb(242, 240, 132);
}
.nav-search {
  border: 2px solid var(--primary);
  background-color: white;
  border-radius: 20px;
  gap: 10px;
  display: flex;
  align-items: center;
  height: 40px;
  width: 45%;
}
.search-bar{

  display: flex;
  justify-content: center;
  width: 97%;
  margin-left: 5px;
  padding-left: 5px;

}

.inputbar {
  border: none;
  border-radius: 5px;
  width: 95%;
  height: 20px;
  outline: none;
  background-color: white;

  font-family: 'Oxygen', sans-serif;
  font-size: 17px;
}

.searchbutton {
  display: flex;
  justify-content: center;
  background-color: transparent;
  align-items: center;


 border: none;
 font-size: 22px;
}

.logoutbutton {
  background-color: #fc3239 !important;
  border-radius: 4px !important;
  border: none !important;
  color: #FFFFFF !important;
  font-size: 12px !important;
  padding: 6px 16px !important;
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
}

.logoutbutton:hover {
  background-color: #aa0006 !important;
}


[data-theme='dark'] {
  --background: #212121;
  --primary: #fff;
}


body {
  background-color: var(--background);
  color: var(--primary);
}

[data-theme='dark'] .NavBar {
  background-color: #282c34;
  color: #fff;
}

[data-theme='dark'] .profile-dropdown {
  background-color: #282c34;
  color: white;
}

[data-theme='dark'] .nav-search.dark-mode {
  background-color: #282c34;
  color: white;

}

[data-theme='dark'] .nav-search {
  color: white;
}



@media screen and (max-width: 1275px) {

  .navStart {
    max-width: 80vw;
    min-width: 20rem;

  }

  .Navbar {
    height: 80px;
  }

  .home-button {
    height: 80px;
    width: 80px;
  }

  .cart-button {
    width: auto;
    font-size: 18px;
  }

  .order-button {
    margin-left: 0;
    font-size: 18px;
  }

  .nav-search {
    width: 40%;
  }

  .inputbar {
    font-size: 14px;
  }

  .searchbutton {
    font-size: 18px;
  }
}

@media screen and (max-width: 576px) {

  .navStart {
    max-width: 80vw;
    min-width: 20rem;
  }

  .Navbar {
    height: 70px;
  }

  .home-button {
    height: 70px;
    width: 70px;
  }

  .cart-button {
    font-size: 16px;
  }

  .order-button {
    font-size: 16px;
  }

  .nav-search {
    width: 30%;
  }

  .inputbar {
    font-size: 12px;
  }

  .searchbutton {
    font-size: 16px;
  }
}
